
._stats{
    margin-top: 20px;
    .card{
        position: relative;
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        font-family: Open Sans;
        background-color: #fff;
        background-clip: border-box;
        border: 0 solid rgba(0,0,0,.125);
        border-radius: 1rem;
        box-shadow: 0 20px 27px 0 rgba(0 ,0 ,0, .05);
        margin-bottom: 20px;    
        min-width: 270px;
        overflow: scroll;
        .card-body {
            font-family: Open Sans;
            padding: 1.5rem;
            flex: 1 1 auto;
        
        }
        .border-radius-md {
            border-radius: .5rem;
        }
        
        .icon-shape {
            width: 48px;
            height: 48px;
            background-position: 50%;
            border-radius: .75rem;
        }
        .bg-gradient-primary {
            background-image: linear-gradient(
        310deg,#7928ca,#ff0080);
        }
        .text-center {
            text-align: center!important;
        }
        .shadow {
            box-shadow: 0 .25rem .375rem -.0625rem hsla(0,0%,8%,.12),0 .125rem .25rem -.0625rem hsla(0,0%,8%,.07)!important;
        }
        .icon {
            fill: currentColor;
            stroke: none;
        }
        .icon-shape {
            .ni {
                top: 14px;
            }
            
        }
        .icon-shape {
            i {
                color: #fff;
                opacity: .8;
                top: 44%;
                position: relative;
                transform: translateY(-50%);
            }
        }
        .text-lg {
            font-size: 1.125rem!important;
        }
        .opacity-10 {
            opacity: 1!important;
        }
        .ni {
            display: inline-block;
            font: normal normal normal 14px/1 NucleoIcons;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        .mb-0 {
            margin-bottom: 0!important;
        }
        .p-3 {
            padding: 1rem!important;
        }
        
        .font-weight-bold {
            font-weight: 600!important;
        }
        .text-sm {
            font-size: .875rem!important;
        }
        
        .text-sm {
            line-height: 1.5;
        }
        .text-capitalize {
            text-transform: capitalize!important;
        }
        .font-weight-bolder {
            font-weight: 700!important;
        }
        
        .text-sm {
            line-height: 1.5;
        }
        .text-success {
            color: #82d616!important;
        }
        h5 {
            font-size: 1.25rem;
            line-height: 1.375;
        }
        .numbers{
            h5{
                color: #344767d4;
                span{
                    margin-left: 5px;
                }
            }
        }
    }
    .react-datepicker-wrapper{
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        margin-top:30px ;
        &:after{
            content: "⌄";
            position: absolute;
            right: 0;
            top: 0;
            transition: all 0.5s ease-in-out;
            font-size: 18px;
            width: 30px;
            height: 30px;
            background-color: #fff;
            border: 0 solid rgba(0, 0, 0, 0.125);
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 25px;
            font-family: 'Open Sens' !important;
            font-weight: 400;
            color: #344767 ;
            padding: 0 0 7px 0;
            box-shadow: 0 .25rem .375rem -.0625rem hsla(0,0%,8%,.12),0 .125rem .25rem -.0625rem hsla(0,0%,8%,.07)!important;
        }
        &:hover{
            &:after{
                background-image: linear-gradient(310deg, #7928ca, #ff0080);
                color: #fff;
            }
        }

    }
    .adminSelectMonthAndYear{  
        color: #344767 !important;
        font-family: 'Open Sans'  !important;
        font-size: 20px !important;
        text-transform: capitalize !important;
        background-color: transparent !important;
        border: none !important;
        width: 220px;
        cursor: pointer;
        padding-left: 0 !important;
        &.font-weight-bolder {
            font-weight: 700!important;
        }
        
    }



}

//tables
.statsSection{
    h6, .h6, .h6 {
        font-size: 1rem;
        line-height: 1.625;
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 400;
        line-height: 1.2;
        color: #344767;
    }
    .mb-0 {
        margin-bottom: 0 !important;
    }
    .align-items-center {
        align-items: center !important;
    }
    .table {
        --bs-table-bg: transparent;
        --bs-table-accent-bg: transparent;
        --bs-table-striped-color: #67748e;
        --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
        --bs-table-active-color: #67748e;
        --bs-table-active-bg: rgba(0, 0, 0, 0.1);
        --bs-table-hover-color: #67748e;
        --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
        width: 100%;
        margin-bottom: 1rem;
        color: #67748e;
        vertical-align: top;
        border-color: #e9ecef;
    }
    html * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    table {
        caption-side: bottom;
        border-collapse: collapse;
    }
    .card {
        .card-header {
            padding: 1.5rem;
        }
    }
    
    .card-header:first-child {
        border-radius: 1rem 1rem 0 0;
    }
    .pb-0 {
        padding-bottom: 0 !important;
    }
    .card-header {
        padding: 0.5rem 1rem;
        margin-bottom: 0;
        background-color: #fff;
        border-bottom: 0 solid rgba(0, 0, 0, 0.125);
    }

    .card {
        box-shadow: 0 20px 27px 0 rgba(0, 0 ,0 ,0.05);
    }
    
    .mb-4 {
        margin-bottom: 1.5rem !important;
    }
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0 solid rgba(0, 0, 0, 0.125);
        border-radius: 1rem;
    }   
    .hiddenTable{
        height: 400px;
        overflow: hidden !important;
        .card-body{
            height: 340px;
            overflow: scroll !important;
        }
        .searchCnt{
            display: flex;
            align-items: center;
            justify-content: space-between;
            h6{
                margin-bottom:0 !important ;
            }
            .input{
                position: relative;
                padding: 10px;
                input{
                    border: 0;
                    font-size: 0.9rem !important;
                    font-family: "Open Sans";
                    min-width: 200px;
                }
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    height: 1px;
                    background-color: rgba(68, 68, 68, 0.697);
                    width: 100%;
                    left: 0;
                }
            }
        }
    }
    .table> :not(:last-child)> :last-child>* {
        border-bottom-color: #e9ecef;
    }
    .table> :not(:last-child)> :last-child>* {
        border-bottom-color: currentColor;
    }
    .table.align-items-center td, .table.align-items-center th {
        vertical-align: middle;
    }
    .table thead th {
        padding: 0.75rem 1.5rem;
        text-transform: capitalize;
        letter-spacing: 0px;
        border-bottom: 1px solid #e9ecef;
    }
    .table td, .table th {
        white-space: nowrap;
    }
    .table th {
        font-weight: 600;
    }
    .table {
        tbody{
            tr{
                &:hover{
                    background: #01546f1f;
                }
            }
        }
    }
    .table> :not(caption)>*>* {
        padding: 0.5rem 0.5rem;
        background-color: var(--bs-table-bg);
        border-bottom-width: 1px;
        box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    }
    .font-weight-bolder {
        font-weight: 700 !important;
    }
    .text-xxs {
        font-size: 0.65rem !important;
    }
    .opacity-7 {
        opacity: 0.7 !important;
    }
    .text-secondary {
        color: #8392AB !important;
    }
    .text-uppercase {
        text-transform: uppercase !important;
    }
    html * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    thead, tbody, tfoot, tr, td, th {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
    }
    th {
        text-align: inherit;
        text-align: -webkit-match-parent;
    }
    *, *::before, *::after {
        box-sizing: border-box;
    }
    feuille de style user-agent
    th {
        display: table-cell;
        vertical-align: inherit;
        font-weight: bold;
        text-align: -internal-center;
    }
    .table {
        --bs-table-bg: transparent;
        --bs-table-accent-bg: transparent;
        --bs-table-striped-color: #67748e;
        --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
        --bs-table-active-color: #67748e;
        --bs-table-active-bg: rgba(0, 0, 0, 0.1);
        --bs-table-hover-color: #67748e;
        --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
        width: 100%;
        margin-bottom: 1rem;
        color: #67748e;
        vertical-align: top;
        border-color: #e9ecef;
    }
    table {
        caption-side: bottom;
        border-collapse: collapse;
    }
    table {
        border-collapse: separate;
        text-indent: initial;
        border-spacing: 2px;
    }
    .card .card-body {
        font-family: "Open Sans";
        padding: 1.5rem;
    }
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0 solid rgba(0, 0, 0, 0.125);
        border-radius: 1rem;
    }
    
    body {
        font-weight: 400;
        line-height: 1.6;
    }
    body {
        margin: 0;
        font-family: var(--bs-font-sans-serif);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #67748e;
        background-color: #fff;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    
    *, *::before, *::after {
        box-sizing: border-box;
    }
    *, *::before, *::after {
        box-sizing: border-box;
    }
    .avatar-sm {
        width: 36px !important;
        height: 36px !important;
        font-size: 0.875rem;
    }
    .avatar {
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        border-radius: 0.75rem;
        height: 48px;
        width: 48px;
        transition: all .2s ease-in-out;
    }
    .me-3 {
        margin-right: 1rem !important;
    }
    .justify-content-center {
        justify-content: center !important;
    }
    .flex-column {
        flex-direction: column !important;
    }
    
    .d-flex {
        display: flex !important;
    }
    .justify-content-center {
        justify-content: center !important;
    }
    .flex-column {
        flex-direction: column !important;
    }
    .d-flex {
        display: flex !important;
    }
    .btnActionTable{
        position: relative;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 1rem;
        // box-shadow: -2px 2px 7px 0 rgba(0, 0 ,0 , 0.14);
        padding: 7px 15px;
        &:hover{
            text-decoration: none !important;
        }
    }

    .font-weight-bold {
        font-weight: 600 !important;
    }
    .text-xs {
        font-size: 0.75rem !important;
    }
    .text-xs {
        line-height: 1.25;
    }
    .text-secondary {
        color: #8392AB !important;
    }
    .numbers{
        text-align: center;   
        p{
            white-space:nowrap;
        }     
    }

    .avatar-Text{
        background: #d6d6d6  !important;
        color: #000 !important;
        margin-right: 1rem !important;
    }
    .showMorSection{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        margin-left: 20px;
        span{
            font-size: 1rem;
            line-height: 1.625;
            font-weight: 400;
            line-height: 1.2;
            color: #344767;
            text-decoration: underline;
            cursor: pointer;
            &:hover{
                text-decoration: none;
            }
        }
    }
}  
.refreshBTn{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    transform: rotate(-229deg);
    width: 29px;
    height: 29px;
    border-radius: 50%;
    padding: 5px;
    background: #d6d6d6;
    color: #004c66;
    cursor: pointer;
    transition: all .5s ease-in-out;
    ion-icon{
        font-size: 35px;
        line-height: 35px;
        padding: 0;
        margin: 0;
    }
    &:hover{
        background: #004c66;
        color: #d6d6d6;
        transform: rotate(0deg);
    }
}


.tableAlert{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0000006b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #be0000;
    font-size: 29px;
    font-weight: 500;
}
.footerTable-cnt{
    margin-top: 10px;
    .cntF{
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
}
.tableFooter {
    width: 40px;
    height: 40px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover{
        background: var(--header-active-menu-color);
        color: #fff;
        border: none;
    }
}
.cursorPointer{
    cursor: pointer !important;
}

.card{
    &.cursorPointer{
        &.active{
            border: 1px solid rgb(51,166, 247) !important;
        }
    }
}


.checkBoxCenter{
    padding-left: 1.5rem !important;
    #droit-switch{
        position: relative;
        right: unset;
        top: unset;
        transform: scale(0.3);
        color: #bad0d5;
        font-size: 20px;
        width: 16px;
        height: 16px;
        border-bottom: 1px solid #bad0d5;
        border-right: 1px solid #bad0d5;
    }
}

#statsSection{
    .row{
        flex-wrap: nowrap;

        .col-xl-2.col-sm-3.mb-xl-0.mb-4.ab{
            padding-right: 8px;
            padding-left: 8px;
            flex: 0 0 13.66667%;
            max-width: 13.66667%;
        }
    }
}

.btnnomotion{
    cursor: pointer;
    &:hover{
        transform: scale(1.1);
        border: 1px solid rgb(51, 166, 247)
    }
}
._m-0{
    margin: 0 !important;
}

.WithOpt{
    display: flex;
    flex-direction: column;
    width: 100%;
    .optionList{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
        .download{
            padding: 0;
            height: 40px;
            width: fit-content;
            border-radius: 12px;
            margin-top: 0;
            font-weight: 400;
            color: #344767;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: revert;
            transition: all .5s ease-in-out;
            margin-left: 0;
            a{
                cursor: pointer;
                border-radius: 7px !important;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: revert;
            }
            span{
                padding: 0;
                margin: 0;
                line-height: 40px;
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                ion-icon{
                    margin: 0;
                    padding: 0;
                    font-size: 21px;
                }
            }
        }
    }
}


// old vr rowTb
.rowTb{
    display: flex;
    justify-content: space-between;
    align-items: center;
}