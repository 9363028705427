.home-headband{
    $pointA: 1430px;
    $pointB: 1199px;
    // background: #fff;
    // padding: 4px;
    border-radius: 5px;
    max-width: 100%; 
    margin-bottom: 15px;
    padding-top: 5px;
    height: 310px;
    background: #fff;

    overflow: auto;
    .owl-carousel{
        width: 710px; 
        video{
            width: 100%;
            display: block;
        }
        .slide-text {
            background: #fff;
            padding: 15px;
            p{
                margin-bottom: 0;
            }
        }
        .owl-stage-outer{
            border-radius: 5px 5px 0 0;
        }
        @media screen and (max-width: $pointA) {
            width: calc( 100vw - 722px ); 
        }
        @media screen and (max-width: $pointB) {
            width: calc( 100vw - 376px ); 
        }
    }
}
//.home-headband .owl-carousel

// 1430