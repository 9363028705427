.ClearCachForce{
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%,-50%);
    
    svg {
        width: 100px;
        overflow: visible;
    }
    svg circle {
        stroke: rgba(255, 255, 255, 0.824);
        transform-origin: 50px 50px;
        animation: spin 1.2s linear infinite;
    }
    
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}